@mixin font-montserrat {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin font-inter {
  font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin primary-xxl {
  @include font-montserrat;
  font-weight: bold;
  font-size: 5.016rem;
  line-height: 1.1;

  @include media(">=md") {
    font-size: 8.345rem;
  }
}

@mixin primary-xl {
  @include font-montserrat;
  font-weight: bold;
  font-size: 3.484rem;
  line-height: 1.3;

  @include media(">=md") {
    font-size: 5.341rem;
  }
}

@mixin primary-large {
  @include font-montserrat;
  font-weight: bold;
  font-size: 2.734rem;
  line-height: 1.3;
}

@mixin primary-big {
  @include font-montserrat;
  font-weight: bold;
  font-size: 2.188rem;
  line-height: 1.4;
}

@mixin primary-medium {
  @include font-montserrat;
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 1.4;
}

@mixin primary-base {
  @include font-montserrat;
  font-weight: bold;
  font-size: rem(14px);
  line-height: 1.6;
}

@mixin secondary-big {
  @include font-inter;
  font-weight: normal;
  font-size: 2.188rem;
  line-height: 1.6;
}

@mixin secondary-medium {
  @include font-inter;
  font-size: 1.75rem;
  font-weight: normal;
  line-height: 1.6;
}

@mixin secondary-base {
  @include font-inter;
  font-size: rem(14px);
  font-weight: normal;
  line-height: 1.6;
}

@mixin secondary-small {
  @include font-inter;
  font-size: rem(11px);
  font-weight: normal;
  line-height: 1.6;
}

@mixin page-category {
  @include font-inter;
  font-weight: normal;
  font-size: rem(14px);
  line-height: 1.6;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

@mixin paragraph {
  @include font-inter;
  font-size: rem(17.5px);
  font-weight: normal;
  line-height: 1.8;
}

@mixin button-reset {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
}

@mixin list-reset {
  margin: 0;
  padding: 0;
}

@mixin list-item-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin hide-scrollbar {
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // Internet Explorer 10+
  &::-webkit-scrollbar {
    // WebKit
    width: 0;
    height: 0;
  }
}
