html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /**
  * Set 1rem = 16px via 62.5%
  * https://engageinteractive.co.uk/blog/em-vs-rem-vs-px
  */
  font-size: 62.5%;

  min-height: 100%;
}

*,
*::before,
*::after {
  /**
   * Box-sizing reset
   * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
   */
  box-sizing: border-box;

  /**
   * Web font replacement
   * https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display
   */
  font-display: swap;
}

body {
  @include font-inter;
  @include secondary-base;
  background-color: white;
  color: $color-bunker;
  font-weight: normal;
  min-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-bunker;
  margin: 0;
}

h1 {
  @include primary-xxl;
}

h2 {
  @include primary-xl;
}

h3 {
  @include primary-large;
}

h4 {
  @include primary-big;
}

h5 {
  @include primary-medium;
}

h6 {
  @include primary-base;
}

p {
  @include secondary-base;
  color: $color-bunker;
  margin: 0;
}

small {
  @include secondary-small;
  color: $color-bunker;
  margin: 0;
}

svg {
  width: 100%;
  height: 100%;
}

input[type="submit"] {
  cursor: pointer;
}

button {
  cursor: pointer;
}

a {
  color: $color-coral;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
}

a:focus,
a:hover {
}

a:focus {
}
