.content-snippet-grid {
  @include list-reset;
  width: 100%;
  display: grid;
  grid-template-columns: 1;
  grid-auto-rows: 1fr;
  row-gap: 140px;

  @include media(">=sm") {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 60px;
  }

  @include media(">=md") {
    column-gap: 100px;
  }
}

.content-snippet-grid__item {
  @include list-item-reset;

  .content-snippet {
    height: 100%;
    width: 100%;
  }
}
