.close-button {
  @include button-reset;
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
}

.close-button__icon {
  height: 13px;
  width: 13px;
  color: white;
}

.close-button__text {
  color: white;
}
