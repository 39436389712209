.ordered-list {
  @include list-reset;
  counter-reset: my-counter;
}

.ordered-list__item {
  @include list-item-reset;
  display: inline-flex;
  align-items: center;
  width: 100%;
  font-size: rem(17px);
  line-height: 1.4;
  counter-increment: my-counter;
  margin-bottom: 30px;
}

.ordered-list__item::before {
  @include font-montserrat;
  font-weight: bold;
  font-size: rem(11px);
  line-height: (14px / 11px);
  color: $color-coral;
  margin-right: 10px;
  content: counter(my-counter, decimal-leading-zero);
}

.ordered-list--dark {
  .ordered-list__item {
    color: $color-concrete;
  }
}

.ordered-list--java {
  .ordered-list__item::before {
    color: $color-java;
  }
}

.ordered-list--featured {
  .ordered-list__item {
    @include primary-medium;
    color: $color-oslo-gray;
    transition: color .3s ease-in-out;
    cursor: pointer;
  }

  .ordered-list__item.is-active {
    color: $color-concrete;
  }
}
