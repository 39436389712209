.nav-menu {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;

  @include media(">=md") {
    flex-direction: row;
    align-items: center;
  }
}

.nav-menu__item {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;

  @include media(">=md") {
    margin: 0 45px 0 0;
  }

  @include media(">=lg") {
    margin: 0 60px 0 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.nav-menu__item__link {
  position: relative;
  color: $color-bunker;
  text-decoration: none;

  &:after {
    background-color: $color-bunker;
    transition: width 50ms linear;
    position: absolute;
    content: "";
    width: 0;
    bottom: -8px;
    left: 0;
    height: 1px;
  }

  &:hover,
  &:focus {
    background: none;
  }

  &:focus {
    opacity: .85;
  }

  &.is-active:after,
  &:hover:after,
  &:focus:after {
    width: 100%;
  }
}

.nav-menu__arrow {
  display: none;
}

.nav-menu--footer {
  .nav-menu__item__link {
    color: $color-concrete;

    &:after {
      background-color: $color-concrete;
    }
  }
}

body.nav-topbar--dark {
  .nav-menu__item__link {
    color: $color-concrete;

    &:after {
      background-color: $color-concrete;
    }
  }
}

.nav-menu--sidebar {
  .nav-menu__item {
    margin: 40px 0 0;
    
    @include media(">=sm") {
      margin: 60px 0 0;
    }
  
    &:last-child {
      margin-right: 0;
    }
  }

  .nav-menu__item__link {
    @include primary-large;
    color: $color-concrete;
    display: flex;
    align-items: center;

    &:after {
      display: none;
    }
  }

  .nav-menu__arrow {
    display: inline-flex;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid $color-concrete;
    color: $color-concrete;
    padding: 5px;
    margin-left: 15px;
  }

  .nav-menu__item__link:hover,
  .nav-menu__item__link:focus,
  .nav-menu__item__link.is-active {
    .nav-menu__arrow {
      background-color: $color-concrete;
      color: $color-bunker;
    }
  }
}
