.callout {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.callout__suptitle {
    @include secondary-medium;
    text-transform: uppercase;
}

.callout__suptitle:not(:last-child) {
    margin-bottom: 20px;
}

.callout__divider {
    width: 40px;
    height: 1px;
    background-color: $color-ebony-clay;
    margin: 0 auto;
    border: 0;
}

.callout__divider:not(:last-child) {
    margin-bottom: 20px;

    @include media(">=sm") {
        margin-bottom: 50px;
    }
}

.callout__title {
    @include primary-xl;
}

.callout__title:not(:last-child) {
    margin-bottom: 40px;
}
