.tags {
  display: flex;
}

.tag {
  display: block;
  font-size: rem(11px);
  line-height: 1.6;
  color: $color-oslo-gray;
  text-transform: uppercase;
  padding: 8px 16px;
  border: 1px solid $color-alto;
  align-self: flex-start;
  margin-bottom: 20px;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }
}
