.progress {
  width: 100%;
  height: 1px;
  background-color: $color-alto;
}

.progress__bar {
  width: 0;
  height: 100%;
  background-color: $color-bunker;
}

.progress--dark {
  background: rgba($color-concrete, 0.2);

  .progress__bar {
    background-color: $color-concrete;
  }
}

.progress--vertical {
  width: 1px;
  height: 100%;

  .progress__bar {
    width: 100%;
    height: 0;
  }
}

.progress--animated {
  .progress__bar {
    transition: all 0.3s ease-in-out;
  }
}
