.text-box {
    padding: 30px;
    border: 1px solid $color-mountain-meadow;
}

.text-box__blockquote {
    @include secondary-medium;
    margin: 0;
}

.text-box__blockquote:not(:last-child) {
    margin-bottom: 60px;
}

.text-box__author {
    @include primary-base;
}
