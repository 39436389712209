.page-header {

}

.page-header__suptitle,
.page-header__title,
.page-header__subtitle {
    color: $color-bunker;
    margin: 0;
}

.page-header__suptitle {
    @include page-category;
}

.page-header__title {
    @include primary-xxl;
}

.page-header__subtitle {
    @include secondary-base;
}

.page-header--dark {
    .page-header__suptitle,
    .page-header__title,
    .page-header__subtitle {
        color: $color-concrete;
    }
}