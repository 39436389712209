/**
 * Creates common width and height helpers similar to Bootstrap
 *
 * For example:
 * 
 * .w-xs-140
 * .h-md-40
 **/
$sizings: 220;

@mixin calcSizing($breakpoint) {
  @each $value in $sizings {
    .w-#{$breakpoint}-#{$value} {
      width: #{$value}px;
    }
    .h-#{$breakpoint}-#{$value} {
      height: #{$value}px;
    }
  }
}

@each $breakpoint, $value in $breakpoints {
  @if $breakpoint != "xs" {
    @include media(">=#{$breakpoint}") {
      @include calcSizing($breakpoint);
    }
  } @else {
    @include calcSizing("xs");
  }
}
