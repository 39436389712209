@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Italic.eot');
    src: url('../fonts/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Italic.woff2') format('woff2'),
        url('../fonts/Montserrat-Italic.woff') format('woff'),
        url('../fonts/Montserrat-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.eot');
    src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/Montserrat-Bold.woff') format('woff'),
        url('../fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.eot');
    src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat-Regular.woff') format('woff'),
        url('../fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-BoldItalic.eot');
    src: url('../fonts/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-BoldItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-BoldItalic.woff') format('woff'),
        url('../fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-BoldItalic.eot');
    src: url('../fonts/Inter-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inter-BoldItalic.woff2') format('woff2'),
        url('../fonts/Inter-BoldItalic.woff') format('woff'),
        url('../fonts/Inter-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.eot');
    src: url('../fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inter-Regular.woff2') format('woff2'),
        url('../fonts/Inter-Regular.woff') format('woff'),
        url('../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Italic.eot');
    src: url('../fonts/Inter-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inter-Italic.woff2') format('woff2'),
        url('../fonts/Inter-Italic.woff') format('woff'),
        url('../fonts/Inter-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Bold.eot');
    src: url('../fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inter-Bold.woff2') format('woff2'),
        url('../fonts/Inter-Bold.woff') format('woff'),
        url('../fonts/Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

