.toc {
  display: none;

  @include media(">=lg") {
    position: absolute;
    display: flex;
    width: 200px;
    z-index: 2;
  }
}

.toc__indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30px;
}

.toc__section-number {
  @include primary-base;
  margin-bottom: 25px;
}

.toc__progress {
  flex: 1;
  margin-bottom: 25px;
}

.toc__menu {
  @include list-reset;
  flex: 1;
  margin-top: 55px;
  margin-bottom: 90px;
  padding-left: 15px;
}

.toc__item {
  @include list-item-reset;
  padding-top: 20px;
  padding-bottom: 20px;
}

.toc__link {
  @include secondary-base;
  color: $color-bunker;
  opacity: 0.5;

  &:focus {
    font-weight: bold;
    opacity: 1;
  }
}

.toc__link.is-active {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  opacity: 1;

  &::before {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $color-vivid-tangerine;
    margin-right: 10px;
    content: "";
  }
}
