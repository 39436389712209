.snippet {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  width: 100%;

  @include media(">=sm") {
    padding-left: 8.33333333%;
    padding-right: 8.33333333%;
  }

  @include media(">=md") {
    width: 370px;
    padding-left: 0;
    padding-right: 80px;
  }
}

.snippet__suptitle {
  font-size: rem(11px);
  font-weight: normal;
  line-height: 1.6;
  letter-spacing: 0.1rem;
  color: $color-bunker;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.snippet__suptitle--big {
  @include primary-medium;
  margin-bottom: 25px;
}

.snippet__suptitle--coral {
  color: $color-coral;
}

.snippet__suptitle--java {
  color: $color-java;
}

.snippet__title {
  @include primary-large;
  color: $color-bunker;
  margin-bottom: 20px;
}

.snippet__title--small {
  @include primary-base;
  margin-bottom: 15px;
}

.snippet__text,
.snippet__richtext p {
  @include secondary-base;
  color: $color-bunker;
}

.snippet__subtext {
  @include secondary-small;
  margin-top: 15px;
  color: $color-bunker;
  opacity: 0.5;
}

.snippet--dark {
  .snippet__suptitle {
    color: $color-vivid-tangerine;
  }

  .snippet__title,
  .snippet__title a,
  .snippet__text,
  .snippet__richtext p {
    color: $color-concrete;
  }
}
