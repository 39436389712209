// This defines the initial state of animated elements,
// so they don't flash while JS is loading.

[data-animate="text-reveal"],
[data-animate="text-reveal-after"] {
  opacity: 0;
}

[data-animate="hotspot"] {
  transform: scale(0.8);
}


