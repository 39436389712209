.show-xs {
  display: block;
}

.flex-xs {
  display: flex;
}

.hide-xs {
  display: none;
}

@include media(">=sm") {
  .show-sm {
    display: block;
  }

  .flex-sm {
    display: flex;
  }

  .hide-sm {
    display: none;
  }
}

@include media(">=md") {
  .show-md {
    display: block;
  }

  .flex-md {
    display: flex;
  }

  .hide-md {
    display: none;
  }
}
