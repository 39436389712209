.partner-label {
  display: flex;
  flex-direction: column-reverse;
  width: 185px;
  padding-left: 20px;

  @include media(">=md") {
    flex-direction: column;
    padding-left: 0;
    text-align: right;
  }
}

.partner-label__logo {
  height: 30px;
  margin: 15px 0 0;
  align-self: flex-start;
  opacity: 0;

  @include media(">=md") {
    margin: 0 0 10px;
    align-self: flex-end;
  }
}

.partner-label__title {
  @include font-montserrat;
  font-size: rem(14px);
  font-weight: bold;
  color: $color-concrete;
  padding-top: 10px;
  text-transform: uppercase;
  position: relative;
  opacity: 0;
}

.partner-label__title::before {
  position: absolute;
  top: 50%;
  left: -18px;
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: translateX(-50%);

  @include media(">=md") {
    display: none;
  }
}

.partner-label--turquoise {
  .partner-label__title::before {
    background-color: $color-bright-turquoise;
  }
}

.partner-label--tangerine {
  .partner-label__title::before {
    background-color: $color-vivid-tangerine;
  }
}

// Note this is an unused colour from the site swatch. It comes from the
// old site
.partner-label--teal {
  .partner-label__title::before {
    background-color: #97d6d8;
  }
}

.partner-label__divider {
  display: none;

  @include media(">=md") {
    display: block;
    background-color: rgba($color-concrete, 0.2);
    width: 0%;
    height: 1px;
    border: 0;
    margin: 0;
  }
}
