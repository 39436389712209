.toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .toggle__label:first-child {
    color: $color-bright-turquoise;
  }
}

.toggle__label {
  @include primary-big;
  color: $color-oslo-gray;
  text-transform: uppercase;
  margin: 10px 0;
  cursor: pointer;
}

.toggle--active {
  .toggle__label:first-child {
    color: $color-oslo-gray;
  }
  .toggle__label:last-child {
    color: $color-bright-turquoise;
  }
}

.toggle__input {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle__switch {
  cursor: pointer;
  text-indent: -9999px;
  height: 50px;
  width: 18px;
  background: transparent;
  border: 1px solid $color-oslo-gray;
  border-radius: 9px;
  display: block;
  position: relative;
}

.toggle__switch:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 14px;
  height: 14px;
  background: $color-bright-turquoise;
  border-radius: 7px;
  transition: 0.3s;
}

.toggle__input:checked + .toggle__switch:after {
  top: calc(100% - 15px);
}
