.screenshot {
  position: relative;
  z-index: 1;
}

.screenshot__perspective {
  transform-style: preserve-3d;
}

.screenshot__image {
}

.screenshot__image--skew-1 {
  transform: skew(-5deg) rotate(-5deg) translate3d(0, 0, 80px);
  box-shadow: -2px -2px 10px 5px rgba(0, 0, 0, 0.1);
}

.screenshot__image--skew-2 {
  transform: skew(5deg) rotate(5deg) translate3d(0, 0, 80px);
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.1);
}

.screenshot__decoration {
  position: absolute;
  z-index: -1;
}

.screenshot__box-1 {
  transform: skew(-5deg) rotate(-5deg);
  background-color: $color-vivid-tangerine;
  height: 50%;
  width: 25%;
  bottom: -15%;
  left: -5%;

  @include media(">=sm") {
    height: 250px;
    width: 150px;
  }
}

.screenshot__circle-1 {
  top: -25px;
  left: -25px;
  width: 75px;

  @include media(">=sm") {
    top: -40px;
    left: -40px;
    width: 150px;
  }
}

.screenshot__circle-2 {
  bottom: -25px;
  right: -100px;
  width: 150px;

  @include media(">=sm") {
    bottom: -75px;
    right: -75px;
    width: 250px;
  }
}

.screenshot__triangle-1 {
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate3d(-30%, -50%, 0);
}

.screenshot__square-1 {
  top: 20%;
  left: -30%;
  height: 100%;
}

.screenshot__chart-1 {
  left: 50%;
  top: -50px;
  width: 100px;
  transform: translate3d(-50px, 0, 0) skew(-5deg) rotate(5deg);

  @include media(">=sm") {
    width: 140px;
    top: -80px;
    transform: translate3d(-70px, 0, 0) skew(-5deg) rotate(5deg);
  }
}

/* Illustrations */

.screenshot__overlapped-waveforms {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: translate3d(-18%, -57%, 0) scale(1.05);
}

.screenshot__dot-grid-square {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 78%;
  opacity: 0.2;
  transform: translate3d(-10%, 31%, 10px) rotate(180deg);
}

.screenshot__triangle-with-plus {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 39%;
  transform: translate3d(44%, 30%, 10px);
}

.screenshot__concentrated-dot-circle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.2;
  transform: translate3d(-15%, -10%, 30px);
}

.screenshot__pacman-patterns {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  width: 73%;
  transform: translate3d(20%, -60%, 10px);
}

.screenshot__dot-globe {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.2;
  transform: translate3d(-27%, -30%, 0) scaleY(-1) rotate(70deg);
}

.screenshot__three-boxes-increasing {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 64%;
  z-index: -1;
  transform: translate3d(40%, 40%, 40px);
}

// // Fade in decorations after image loads
// .screenshot__decoration {
//   transition: opacity 100ms linear;
//   opacity: 1;
// }

// .screenshot__image.lazyloaded + .screenshot__decoration {
//   opacity: 1;
// }
