.content-header {
}

.content-header__suptitle {
  @include page-category;
  text-transform: uppercase;
  color: $color-bunker;
  margin-bottom: 20px;
}

.content-header__title {
  @include primary-xl;
  margin-bottom: 40px;
  font-size: rem(29px);
  line-height: 1.3;

  @include media(">=sm") {
    font-size: rem(43px);
    line-height: 1.2;
  }
}

.content-header__subtitle {
  @include secondary-medium;
  margin-bottom: 40px;

  @include media(">=sm") {
    @include secondary-big;
  }
}

.content-header__date {
  @include secondary-base;
  color: $color-bunker;
  opacity: 0.5;
}
