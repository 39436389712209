.faq {
}

.faq__header {
  @include primary-medium;
  padding-bottom: 60px;
}

.faq__question {
  display: flex;
  flex-direction: column-reverse;

  @include media(">=sm") {
    flex-direction: column;
  }
}

.faq__question-text {
  margin-bottom: 30px;

  @include media(">=md") {
    margin-bottom: 0px;
  }
}

.faq__question-text.rich-text-content--small p {
  font-weight: bold;
  margin: 0;

  @include media(">=md") {
    font-weight: normal;
  }
}

.faq__tags {
  @include media(">=md") {
    margin-top: 20px;
  }
}
