.carousel {
  width: 100%;
  padding: 40px 0;
  overflow: hidden;

  @include media(">=sm") {
    padding: 60px 0;
  }

  @include media(">=md") {
    margin-left: 8.33333333%;
  }

  @include media(">=lg") {
    margin-left: (100/12 * $container-width)/100;
  }
}

.carousel--offset-2 {
  @include media(">=lg") {
    margin-left: (2 * 100/12 * $container-width)/100;
  }
}

.carousel__content {
  position: relative;
  white-space: nowrap;
  padding-bottom: 40px;
  transition: all .3s ease-in-out;

  @include media(">=md") {
    display: inline-block;
    padding-bottom: 80px;
  }
}

.carousel__indicator {
  width: 100%;
  text-align: right;
  margin-left: auto;
  margin-right: auto;

  @include media(">=sm") {
    width: 83.33333334%;
  }

  @include media(">=md") {
    margin-left: 0;
  }
}

.carousel__progress {
  margin-bottom: 20px;
}
