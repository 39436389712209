.rich-text-content {
  h2:first-child {
    margin-top: 0;
  }

  h2,
  h3,
  h4 {
    margin-bottom: 30px;
  }

  h2 {
    @include primary-large;
    margin-top: 60px;
  }

  h3 {
    @include primary-big;
  }

  h4 {
    @include primary-medium;
  }

  p,
  ol > li,
  ul > li,
  table td {
    @include paragraph;
  }

  a {
    position: relative;

    &:after {
      background-color: $color-bright-turquoise;
      position: absolute;
      content: "";
      width: 100%;
      bottom: -3px;
      left: 0;
      height: 1px;
    }
  }

  p {
    margin-bottom: 30px;
  }

  p:last-child {
  }

  ul,
  ol {
    padding: 0;
    margin-bottom: 60px;

    li {
      padding-left: 10px;
      margin-bottom: 20px;
      margin-left: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      margin-top: 20px;
    }
  }

  strong {
  }

  blockquote {
  }

  small {
  }

  table {
    td {
    }
  }

  figure {
    a {
    }

    img {
    }
  }

  figcaption {
  }

  mark {
    background-color: $color-coral;
    color: white;
    padding: 0 5px;
  }

  var {
  }

  kbd {
  }

  sup {
  }

  sub {
  }

  aside {
  }
}

.rich-text-content--small {
  p,
  ol > li,
  ul > li,
  table td {
    @include secondary-base;
  }

  p {
    margin-bottom: 20px;
  }

  ul,
  ol {
    padding: 0;
    margin-bottom: 30px;

    li {
      margin-bottom: 10px;
    }

    ul,
    ol {
      margin-top: 10px;
    }
  }
}
