.bio-header {
    font-size: rem(14px);
    line-height: 1.6;
    color: $color-bunker;
    text-transform: uppercase;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-bunker;
    margin-bottom: 40px;

    @include media(">=sm") {
        display: none;
    }
}

.bio-header--light {
    color: $color-concrete;
    border-color: $color-concrete;
}
