.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate3d(-50%, -50%, 0);
  }

  &::after,
  &::before {
    display: block;
    content: "";
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 28.5%;
  }

  &::before {
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(3, 19, 31, 0) 0%, $color-bunker 100%), linear-gradient(180deg, rgba(3, 19, 31, 0) 0%, $color-bunker 100%), linear-gradient(180deg, rgba(3, 19, 31, 0) 0%, $color-bunker 100%);
    transform: rotate(180deg);
  }

  &::after {
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(3, 19, 31, 0) 0%, #03080D 100%);
  }
}

.bg-video__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;

  &::before {
    display: block;
    content: "";
    position: absolute;
    z-index: 4;
    width: 80%;
    height: 100%;
    background: linear-gradient(to left, rgba(3, 19, 31, 0) 0%, #03080D 100%);
  }
}
