$default-font-size: 16px;

$color-bright-turquoise: #25d0bf;
$color-java:             #09aa99;
$color-mountain-meadow:  #159e8f;
$color-gossamer:         #089184;
$color-vivid-tangerine:  #ff9975;
$color-coral:            #ff875c;
$color-jaffa:            #f6794c;

$color-bunker:     #161C22;
$color-ebony-clay: #27313A;
$color-pale-sky:   #6d7880;
$color-oslo-gray:  #8A8D90;
$color-alto:       #D6D6D6;
$color-concrete:   #F2F2F2;
$color-alabaster:  #F7F7F7;

$container-width: 1200px;
$container-gutter: 20px;

$breakpoints: (
  xs: $xs * 1px,
  sm: $sm * 1px,
  md: $md * 1px,
  lg: $lg * 1px,
  xl: $xl * 1px
);

$grid-gutter-width: 20px;
$grid-gutter-compensation: -$grid-gutter-width * 0.5;
$grid-half-gutter-width: $grid-gutter-width * 0.5;
$grid-outer-margin: $grid-half-gutter-width;
