.layout {
    column-count: 1;

    @include media(">=sm") {
        column-count: 2;
    }
}

.layout--shift::before {
    @include media(">=sm") {
        display: block;
        content: "\00a0";
    }
}
