.hero {
  position: relative;
  background-color: $color-bunker;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.hero__background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  z-index: 1;

  &:after {
    display: block;
    content: "";
    background: linear-gradient(45deg, rgba($color-bunker, 0.5) 0%, $color-bunker 60%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.hero__svg {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  color: rgba(255, 255, 255, 0.2);
  stroke-width: 0.1px;
  transform: scale(4) translate3d(40%, -4.8%, 0);

  @include media(">=sm") {
    top: -20%;
    right: -20%;
    transform: scale(1);
  }
}

.hero__content {
  position: relative;
  z-index: 2;
}

.hero__title {
  @include primary-xxl;
  color: $color-concrete;
}

.hero__subtitle {
  @include secondary-base;
  color: $color-concrete;
}
