.social-icons {
  @include list-reset;
  display: inline-flex;
  flex-direction: row;

  @include media(">=md") {
    flex-direction: column;
  }
}

.social-icons__item {
  @include list-item-reset;
  margin-right: 15px;

  @include media(">=md") {
    margin-bottom: 15px;
  }
}

.social-icons__link {
  display: block;
}

.social-icons__icon {
  margin: 0;
  height: 30px;
  width: 30px;
  color: $color-bunker;
}
