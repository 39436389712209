.content-snippet {
}

.content-snippet__image {
  margin-bottom: 30px;
}

.content-snippet__image__img {
  width: 100%;
  height: 330px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.content-snippet__content {
  @include media(">=md") {
    padding-left: 30px;
  }
}

.content-snippet__suptitle {
  @include secondary-small;
  color: $color-bunker;
  opacity: 0.5;
  margin-bottom: 10px;
}

.content-snippet__title {
  margin-bottom: 25px;
}

.content-snippet__title,
.content-snippet__title a {
  @include primary-big;
  color: $color-bunker;
}

.content-snippet__text {
  p {
    @include secondary-base;
    color: $color-bunker;
  }
}

.content-snippet__link {
  margin-top: 25px;
  display: flex;
  justify-content: center;

  @include media(">=sm") {
    justify-content: flex-start;
  }
}
