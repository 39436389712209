.quote {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  text-align: center;
  width: 100%;

  @include media(">=md") {
    vertical-align: top;
    text-align: left;
    width: 360px;
    padding-right: 80px;
  }
}

.quote__text {
  @include primary-medium;
  margin-bottom: 10px;
}

.quote__author {
  @include secondary-base;
}
