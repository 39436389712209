.slider {
    position: relative;
    
    @include media(">=md") {
        padding: 0 40px;
    }
}

.slider__frame {
    overflow: hidden;
}

.slider__stream {
    position: relative;
    left: 0;
    display: flex;
    transition: all .3s ease-in-out;
}

.slider__slide {
    @include secondary-base;
    color: $color-concrete;
    text-align: center;
    margin: 0;
    padding-left: 5px;
    padding-right: 5px;
    flex-shrink: 0;

    @include media(">=md") {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.slider__image {
    display: block;
    width: 100%;
    margin-bottom: 30px;
}

.slider__controller {
    display: flex;
    justify-content: space-between;
    width: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    @include media(">=md") {
        position: absolute;
        top: calc(50% - 30px);
        left: 0;
        width: 100%;
        margin-top: 0;
    }
}

.slider__button {
    @include button-reset;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $color-concrete;
    width: 30px;
    height: 30px;
    padding: 5px;
    cursor: pointer;

    &:nth-child(1) {
        transform: rotate(180deg);
    }
}

.slider__button.is-disabled {
    opacity: .2;
    pointer-events: none;
}
