.partner-map {
    position: relative;
    margin: 0 auto;
    width: 100%;

    @include media(">=md") {
        margin-top: 150px;
    }
}

.partner-map__regions {
    position: relative;
    margin-bottom: 60px;
}

.partner-map__background {
    max-width: 100%;
}

.partner-map__region {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    opacity: 0;
}

.partner-map__tooltip {
    @include media(">=md") {
        position: absolute;

        &::before {
            position: absolute;
            bottom: -40px;
            left: -1px;
            display: block;
            width: 1px;
            height: 0%;
            content: "";
            background-color: rgba($color-concrete, 0.2);
        }
    }
}

// Tooltip positioning (before show)
.partner-map__tooltip--americas {
    @include media(">=md") {
        top: -180px;
        left: 46px;
        height: 223px;
    }
}

.partner-map__tooltip--europe {
    @include media(">=md") {
        top: -215px;
        left: 363px;
        height: 330px;
    }
}

.partner-map__tooltip--asia {
    @include media(">=md") {
        top: -145px;
        right: 12px;
        height: 160px;
    }
}


.partner-map.is-visible {
    // Tooltip animation
    .partner-map__tooltip--americas {
        .partner-label__logo,
        .partner-label__title {
            transition: all .3s linear;
            opacity: 1;
        }
    }

    .partner-map__tooltip--europe {
        .partner-label__logo,
        .partner-label__title {
            transition: all .3s linear .3s;
            opacity: 1;
        }
    }

    .partner-map__tooltip--asia {
        .partner-label__logo,
        .partner-label__title {
            transition: all .3s linear .6s;
            opacity: 1;
        }
    }

    // Map animation
    .partner-map__region--americas {
        transition: all .5s linear .6s;
        opacity: 1;
    }

    .partner-map__region--europe {
        transition: all .5s linear 1.1s;
        opacity: 1;
    }

    .partner-map__region--asia {
        transition: all .5s linear 1.7s;
        opacity: 1;
    }

    @include media(">=md") { 
        // Map animation
        .partner-map__region--americas {
            transition: all .5s linear;
            opacity: 1;
        }

        .partner-map__region--europe {
            transition: all .5s linear .5s;
            opacity: 1;
        }
    
        .partner-map__region--asia {
            transition: all .5s linear 1s;
            opacity: 1;
        }

        // Tooptip animation 
        .partner-map__tooltip--americas {
            &::before {
                transition: all .3s linear 1s;
                height: 100%;
            }
            .partner-label__divider {
                transition: all .3s linear 1.3s;
                width: 100%;
            }
            .partner-label__logo,
            .partner-label__title {
                transition: all .3s linear 1.6s;
                opacity: 1;
            }
        }

        .partner-map__tooltip--europe {
            &::before {
                transition: all .3s linear 1.9s;
                height: 100%;
            }
            .partner-label__divider {
                transition: all .3s linear 2.2s;
                width: 100%;
            }
            .partner-label__logo,
            .partner-label__title {
                transition: all .3s linear 2.5s;
                opacity: 1;
            }
        }
    
        .partner-map__tooltip--asia {
            &::before {
                transition: all .3s linear 2.6s;
                height: 100%;
            }
            .partner-label__divider {
                transition: all .3s linear 2.9s;
                width: 100%;
            }
            .partner-label__logo,
            .partner-label__title {
                transition: all .3s linear 3.2s;
                opacity: 1;
            }
        }
    }
}
