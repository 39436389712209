.category-heading {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid $color-bunker;

  @include media(">=md") {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.category-heading__title {
  @include primary-xl;
  line-height: 1;
  color: $color-bunker;
  flex: 1 1 60%;
}

.category-heading__description {
  @include secondary-base;
  color: $color-bunker;
  flex: 1 1 30%;
  margin-top: 20px;

  @include media(">=md") {
    margin-top: 0;
  }
}
