.container {
  position: relative;
  padding-left: $container-gutter;
  padding-right: $container-gutter;
  width: 100%;

  @include media(">=lg") {
    width: $container-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.container--no-overflow {
  overflow: hidden;
}

.container--no-edges {
  &::after,
  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    z-index: 2;
    width: 20px;
    height: 100%;

    @include media(">=sm") {
      width: 10%;
    }
  }
  &::before {
    left: 0;
    background-image: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
  }
  &::after {
    right: 0;
    background-image: linear-gradient(to left, #fff 0%, rgba(255, 255, 255, 0) 100%);
  }
}

.container-fluid {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.container-offset {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include media(">=md") {
    width: 100%;
    max-width: calc(50% + #{$container-width / 2});
  }
}

.container-offset--third {
  @include media(">=lg") {
    max-width: calc(50% + #{$container-width / 3});
  }
}

.container-offset--two-images {
  padding-left: $container-gutter;
  padding-right: $container-gutter;

  @include media(">=md") {
    max-width: 100%;
    padding-left: calc(25% + 15px);
    padding-right: 0;
  }

  @include media(">=lg") {
    max-width: calc(50% + #{$container-width / 4});
    padding-left: 15px;
  }
}
