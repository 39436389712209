.controller {
  display: inline-flex;
}

.controller__button {
  @include button-reset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $color-bunker;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.controller__button svg {
  width: 13px;
  height: 13px;
}

.controller__button:nth-child(1) {
  margin-right: 5px;

  & svg {
    transform: rotate(180deg);
  }
}

.controller--dark {
  .controller__button {
    color: $color-concrete;
  }
}

.controller--vertical {
  flex-direction: column;
  width: 100%;

  .controller__button:nth-child(1) {
    margin-right: 0;

    svg {
      transform: rotate(-90deg);
    }
  }

  .controller__button:nth-child(2) {
    svg {
      transform: rotate(90deg);
    }
  }
}
