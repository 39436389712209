// A circular list for desktop
@mixin circular-list($item-count, $circle-size, $offset: 50px) {
  width: $circle-size;
  height: $circle-size;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > .circular-list__item {
    position: absolute;
    display: block;
    max-width: 150px;

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg)
          translate(($circle-size / 2) - $offset)
          rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}

// A regular x2 grid for mobile
@mixin grid-list() {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 60px;
  text-align: left;

  > .circular-list__item {
    max-width: auto;
  }
}

.circular-list-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  @include media(">=md") {
    justify-content: flex-end;
  }
}

.circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 600px;
  height: 600px;
  padding-left: 5%;
  padding-right: 5%;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 600px;
    height: 600px;
    border: 1px solid $color-concrete;
    border-radius: 50%;
    content: "";
    display: block;
    transform: translate3d(-50%, -50%, 0);
  }
}

.circle--no-line {
  &::before {
    display: none;
  }
}

.circle__svg {
  width: 600px;
  height: 600px;
  position: absolute;
  z-index: 2;
  transform: rotate(90deg);
  stroke: $color-coral;
}

.circular-list {
  @include list-reset;
}

.circular-list__item {
  @include list-item-reset;
  @include secondary-base;
  color: $color-alabaster;
}

// Show/hide the dimensions list
.circular-list--dimensions {
  display: none;
}

.circular-list-wrapper:not(.circular-list--active) .circular-list--dimensions {
  @include media("<md") {
    @include grid-list();
  }

  @include media(">=md") {
    @include circular-list(12, 600px);
  }
}

// Show/hide the analytics list
.circular-list--analytics {
  display: none;
}

.circular-list--active .circular-list--analytics {
  @include media("<md") {
    @include grid-list();
  }

  @include media(">=md") {
    @include circular-list(10, 600px, $offset: 80px);
  }
}

// Center the toggle
.toggle {
  position: static;
  margin-bottom: 40px;

  @include media(">=md") {
    position: absolute;
  }
}
