.height-xs-auto {
  height: auto;
}

@include media(">=sm") {
  .height-sm-auto {
    height: auto;
  }
}

@include media(">=md") {
  .height-md-auto {
    height: auto;
  }
}

@include media(">=lg") {
  .height-lg-auto {
    height: auto;
  }
}

.height-xs-100 {
  height: 100%;
}

@include media(">=sm") {
  .height-sm-100 {
    height: 100%;
  }
}

@include media(">=md") {
  .height-md-100 {
    height: 100%;
  }
}

@include media(">=lg") {
  .height-lg-100 {
    height: 100%;
  }
}
