.lede {
  margin-left: auto;
  margin-right: auto;
}

.lede__icon {
  display: block;
  width: 50px;
  margin-bottom: 20px;
}

.lede__suptitle {
}

.lede__title {
  @include primary-xl;
}

.lede__title:not(:last-child) {
  margin-bottom: 5px;
}

.lede__title--small {
  @include primary-large;
}

.lede__title--small:not(:last-child) {
  margin-bottom: 30px;
}

.lede__title--tiny {
  @include primary-big;
}

.lede__title--tiny:not(:last-child) {
  margin-bottom: 20px;
}

.lede__text {
  @include secondary-base;

  a {
    border-bottom: 1px solid $color-jaffa;
    padding-bottom: 3px;
  }

  a:focus, a:hover {
    border-bottom: 1px solid $color-java;
  }
}

.lede__text--oslo {
  color: $color-oslo-gray;
}

.lede__text--medium {
  @include secondary-medium;
}

.lede__text--pale-sky {
  color: $color-pale-sky;
}

.lede__quote {
  @include font-montserrat;
  font-weight: bold;
  font-size: rem(100px);
  line-height: 1;
  color: $color-coral;
}

.lede--dark {
  .lede__title,
  .lede__text {
    color: $color-concrete;
  }

  .lede__text a {
    white-space: nowrap;
    color: $color-concrete;
    border-color: $color-concrete;
  }
}

.lede--bullet {
  padding-left: 20px;

  .lede__title {
    position: relative;
  }

  .lede__title::before {
    position: absolute;
    top: 50%;
    left: -22px;
    transform: translate3d(0, -50%, 0);
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $color-bright-turquoise;
    content: "";
  }
}
