.nav-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 20px 10px 30px 20px;
  transition: transform 100ms ease-in-out;
  transform: translate3d(-100%, 0, 0);
  display: flex;
  flex-direction: column;
  background-color: $color-bunker;
  z-index: 102;

  @include media(">=sm") {
    max-width: 400px;
  }

  @include media(">=md") {
    display: none;
  }
}

.nav-sidebar--show {
  transform: translate3d(0, 0, 0);

  @include media(">=sm") {
    box-shadow: 2px 4px 10px rgba(0, 0, 0, .5);
  }
}

.nav-sidebar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-sidebar__nav {
  display: flex;
  align-items: center;
  height: 100%;
}

.nav-sidebar__link {
  display: block;

  &:hover,
  &:focus {
    background: none;
    border: 0;
  }
}

.nav-sidebar__logo {
  display: block;
  margin: 0;
  height: 30px;
  max-width: 175px;

  @include media(">=md") {
    max-width: 220px;
    height: 40px;
  }
}
