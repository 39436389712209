.lead {
  margin-left: auto;
  margin-right: auto;
}

.lead__suptitle {
  @include primary-base;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.lead__suptitle:not(:last-child):not(.lead__suptitle--small) {
  margin-bottom: 10px;
}

.lead__suptitle--small {
  @include secondary-small;
}

.lead__suptitle--light {
  font-weight: normal;
}

.lead__suptitle--java {
  color: $color-java;
}

.lead__suptitle--coral {
  color: $color-coral;
}

.lead__suptitle--concrete {
  color: $color-concrete;
}

.lead__suptitle__line {
  height: 1px;
  background-color: $color-bunker;
  width: 30px;
  margin: 2px 10px 0 10px;
}

.lead__title {
  @include primary-xl;
}

.lead__title:not(:last-child) {
  margin-bottom: 30px;
}

.lead__subtitle {
  @include secondary-medium;
  margin-bottom: 20px;
  font-weight: bold;
}

.lead__divider {
  width: 60px;
  height: 1px;
  background-color: #ffffff;
  margin: 0;
  border: 0;
}

.lead__divider:not(:last-child) {
  margin-bottom: 20px;
}

.lead__text {
  @include secondary-base;
}

.lead__text:not(:last-child) {
  margin-bottom: 40px;
}

.lead__text--big {
  @include secondary-big;
}

.lead__text--narrow {
  width: 100%;

  @include media(">md") {
    width: 75%;
  }
}

.lead__text--pale-sky {
  color: $color-pale-sky;
}

.lead--dark {
  .lead__title,
  .lead__subtitle,
  .lead__suptitle,
  .lead__text {
    color: #ffffff;
  }
  .lead__suptitle__line {
    background: #ffffff;
  }
}
