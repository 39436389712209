$nav-xs-height: 70px;
$nav-sm-height: 120px;

body:not(.nav-topbar--fixed) {
  .nav-wrapper {
    margin: 0;
    padding: 0;
    width: 100%;
    height: $nav-xs-height;

    @include media(">=md") {
      height: $nav-sm-height;
    }
  }
}

.nav-topbar {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px;
  width: 100%;

  @include media(">=md") {
    padding: 40px;
  }

  .nav {
    display: none;

    @include media(">=md") {
      display: block;
    }
  }
}

.nav-topbar__trigger {
  @include button-reset;

  @include media(">=md") {
    display: none;
  }
}

.nav-topbar__hamburger {
  width: 16px;
  height: 16px;
  color: $color-bunker;

  @include media(">=md") {
    display: none;
  }
}

.nav-topbar__link {
  display: block;

  &:hover,
  &:focus {
    background: none;
    border: 0;
  }
}

.nav-topbar__logo {
  display: block;
  margin: 0;
  height: 30px;
  max-width: 175px;

  @include media(">=md") {
    max-width: 220px;
    height: 40px;
  }
}

.nav-topbar__logo--light {
  display: block;
}

.nav-topbar__logo--dark {
  display: none;
}

body.nav-topbar--dark {
  .nav-topbar {
    background-color: $color-bunker;
  }

  .nav-topbar__logo--light {
    display: none;
  }

  .nav-topbar__logo--dark {
    display: block;
  }

  .nav-topbar__hamburger {
    color: $color-concrete;
  }
}

body.nav-topbar--fixed {
  .nav-topbar {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }
}

body.nav-topbar--fixed:not(.scrolling) {
  .nav-topbar {
    background-color: transparent;
  }
}

body.scrolling.show-fixed-nav {
  .nav-topbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    transform: translate3d(0, 0, 200px); // make navbar appear over Tilt.js elements
  }
}
