// Align Center

.tc-xs {
  text-align: center;
}

@include media(">=sm") {
  .tc-sm {
    text-align: center;
  }
}

@include media(">=md") {
  .tc-md {
    text-align: center;
  }
}

@include media(">=lg") {
  .tc-lg {
    text-align: center;
  }
}

@include media(">=xl") {
  .tc-xl {
    text-align: center;
  }
}

// Align Left

.tl-xs {
  text-align: left;
}

@include media(">=sm") {
  .tl-sm {
    text-align: left;
  }
}

@include media(">=md") {
  .tl-md {
    text-align: left;
  }
}

@include media(">=lg") {
  .tl-lg {
    text-align: left;
  }
}

@include media(">=xl") {
  .tl-xl {
    text-align: left;
  }
}

// Align Right

.tr-xs {
  text-align: right;
}

@include media(">=sm") {
  .tr-sm {
    text-align: right;
  }
}

@include media(">=md") {
  .tr-md {
    text-align: right;
  }
}

@include media(">=lg") {
  .tr-lg {
    text-align: right;
  }
}

@include media(">=xl") {
  .tr-xl {
    text-align: right;
  }
}
