/**
 * Creates common column count helpers
 *
 * For example:
 * 
 * .columns-xs-3
 **/
$columns: 1, 2, 3, 4, 5;

@mixin calcColumns($breakpoint) {
  @each $column in $columns {
    .columns-#{$breakpoint}-#{$column} {
      column-count: #{$column};
    }
  }
}

@each $breakpoint, $value in $breakpoints {
  @if $breakpoint != "xs" {
    @include media(">=#{$breakpoint}") {
      @include calcColumns($breakpoint);
    }
  } @else {
    @include calcColumns("xs");
  }
}
