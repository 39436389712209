.square-btn {
    padding: 16px 28px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    font-size: rem(14px);
    font-weight: bold;
    line-height: 1.6;
    text-transform: uppercase;
    transition: all .2s ease-out;
}

.square-btn__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    padding: 7px;
    color: $color-bunker;
    border-radius: 50%;
    margin-left: 40px;
    transition: all .3s ease-out;
}

.square-btn--java {
    background-color: $color-java;
    color: $color-concrete;

    .square-btn__icon {
        background-color: $color-concrete;
        color: $color-java;
    }
}


.square-btn--java:hover,
.square-btn--java:focus {
    background-color: $color-gossamer;
}

.square-btn--coral {
    background-color: $color-coral;
    color: $color-concrete;

    .square-btn__icon {
        background-color: $color-concrete;
        color: $color-coral;
    }
}


.square-btn--coral:hover,
.square-btn--coral:focus {
    background-color: $color-jaffa;
}

.square-btn--outline-dark {
    border: 1px solid $color-concrete;
    color: $color-concrete;

    .square-btn__icon {
        background-color: $color-vivid-tangerine;
        color: $color-bunker;
    }
}

.square-btn--outline-dark:hover,
.square-btn--outline-dark:focus {
    background-color: $color-concrete;
    color: $color-bunker;
}

.square-btn:hover,
.square-btn:focus {
    .square-btn__icon {
        margin-left: 50px;
    }
}
