.image {
  position: relative;
  width: 100%;
  margin: 0;
}

.image__img {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
}

.image__img--bordered {
  border: 20px solid white;
  border-radius: 20px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
}

.image--shift-right {
  width: calc(100% + #{$grid-gutter-width * 2});
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;

  @include media(">=sm") {
    width: 100%;
    overflow: visible;
  }

  .image__img {
    transform: translateX(20px);
    min-width: 560px;

    @include media(">=sm") {
      transform: none;
    }
  }
}

.image__illustration {
  position: absolute;
}

.image__illustration--bisecting-graph-lines {
  display: none;

  @include media(">=md") {
    display: block;
    bottom: 10px;
    left: 10px;
    transform: translate3d(-50%, 50%, 0);
    width: 280px;
    height: 280px;
  }
}

.image__illustration--bg-dotted-shape {
  bottom: -2%;
  right: -8%;
  width: 25%;
}
