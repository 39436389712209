.portrait {

}

.portrait__image {
    height: 30px;
    width: 30px;
    display: inline-flex;
    border: 1px solid $color-coral;
    border-radius: 15px;
}

.portrait__image__img {
    height: 28px;
    width: 28px;
    border-radius: 14px;
    border: 1px solid transparent;
    margin-bottom: 8px;
}

.portrait__name {
    @include secondary-base;
    color: $color-ebony-clay;
    opacity: .5;
}