.bio {
}

.bio__title {
    @include primary-medium;
    margin-bottom: 5px;
}

.bio__subtitle {
    @include secondary-medium;
    color: $color-oslo-gray;
    margin-bottom: 30px;
}

.bio__divider {
    width: 80px;
    height: 1px;
    background-color: $color-alto;
    border: 0;
    margin: 0 0 30px;
}

.bio__text {
    p {

    }

    p:not(:last-child) {
        margin-bottom: 30px;
    }
}

.bio__email {
    margin-top: 10px;

    a {
        color: $color-jaffa;
        text-decoration: underline;
    }
}

.bio--small {
    .bio__title,
    .bio__subtitle {
        font-size: rem(14px);
    }
}
