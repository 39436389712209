.divider {
  width: 100%;
  height: 1px;
  background-color: $color-alto;
  margin: 0;
  border: 0;
}

.divider--oslo-gray {
  background-color: $color-oslo-gray;
}
