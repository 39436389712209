.lollipop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16px;
}

.lollipop__stick {
  height: 1px;
  width: 100%;
  background-color: $color-bunker;
}

.lollipop__circle {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: $color-vivid-tangerine;
}

.lollipop--advisory {
  position: absolute;
  top: -30px;
  left: 0;
  width: 20%;

  @include media(">=md") {
    top: 0;
    width: calc(50% - #{$container-width/2} + #{$container-width/12 * 7});
  }
}

.lollipop--hero-1 {
  position: absolute;
  width: calc(66.66666667% + 30px);
  top: -40px;
  right: 0;
  transform: rotate(180deg);

  @include media(">=sm") {
    width: calc(50% - #{$container-width/2} + #{$container-width/12 * 5 + 60});
  }

  .lollipop__stick {
    background-color: $color-alto;
  }
}

.lollipop--hero-2 {
  position: absolute;
  width: 20%;
  top: 5px;
  left: 0;

  @include media(">=sm") {
    top: 40px;
  }

  @include media(">=lg") {
    width: calc(57.333333% - #{$container-width/2});
    top: 140px;
  }

  .lollipop__circle {
    background-color: $color-bright-turquoise;
  }

  .lollipop__stick {
    background-color: $color-alto;
  }
}

.lollipop--about {
  position: absolute;
  left: -160px;
  top: 0;
  width: 16px;
  height: 400px;
  flex-direction: column;
  transform: rotate(180deg);

  .lollipop__stick {
    width: 1px;
    height: 100%;
  }
}
