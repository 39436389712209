.info-box {
  width: 50%;

  @include media(">=sm") {
    width: 75%;
  }

  @include media(">=md") {
    width: 90%;
  }
}

.info-box__suptitle {
  @include secondary-base;
  color: $color-concrete;
}

.info-box__divider {
  display: inline-block;
  background-color: $color-concrete;
  vertical-align: middle;
  margin: 0 0 0 5px;
  width: 30px;
  height: 1px;
  border: 0;
}

.info-box__title {
  @include font-montserrat;
  font-size: rem(34px);
  font-weight: bold;
  line-height: 1.2;
  text-transform: capitalize;
  color: $color-concrete;
  margin-bottom: 15px;
}

.info-box__text {
  @include secondary-base;
  color: $color-concrete;
}
