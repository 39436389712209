.dashboard-snippet {
  border: 1px solid $color-concrete;
  padding: 30px 30px 45px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 210px;

  @include media(">=md") {
    width: 370px;
    margin-right: 80px;
  }
  
  &:active {
    transform: translate3d(1px, 1px, 0);
  }
}

.dashboard-snippet__text {
  @include primary-medium;
  color: $color-concrete;
}

.dashboard-snippet__icon {
  width: 93px;
  height: 68px;
}
