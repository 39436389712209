.big-image {
}

.big-image--expanded {
  width: calc(100% + #{$grid-gutter-width * 2});
  margin-left: -$grid-gutter-width;

  @include media(">=md") {
    width: 100%;
    margin-left: 0;
  }
}

.big-image--no-overflow {
  overflow: hidden;
}

.big-image__img {
  width: 100%;
  display: none;
}

.big-image__bg {
  display: block;
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.big-image__bg--half {
  height: 300px;
}

@include media(">=sm") {
  .big-image__img {
    display: block;
  }

  .big-image__bg {
    display: none;
  }
}
