.single-link {
    @include primary-base;
    // display: inline-block;
    color: $color-bunker;
    display: inline-flex;
    width: auto;
}

.single-link__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    padding: 7px;
    color: $color-bunker;
    border-radius: 50%;
    margin-left: 10px;
    transition: all .3s ease-out;
}

.single-link__icon--rotate-90 {
    transform: rotate(90deg);
}

.single-link__icon--outline {
    background-color: transparent;
    border: 1px solid $color-bunker;
    padding: 6px;

    svg {
        color: $color-bunker;
    }
}

.single-link__icon--turquoise {
    background-color: $color-bright-turquoise;
}

.single-link__icon--tangerine {
    background-color: $color-vivid-tangerine;
}

.single-link__icon--coral {
    background-color: $color-coral;
}

.single-link--vertical {
    flex-direction: column;
    align-items: center;

    .single-link__icon {
        margin: 10px 0 0 0;
    }
}

.single-link:hover,
.single-link:focus {
    .single-link__icon {
        margin-left: 20px;
    }
}

.single-link--vertical:hover,
.single-link--vertical:focus {
    .single-link__icon {
        margin: 20px 0 0 0;
    }
}
