.cta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px 20px 50px;
  background-color: $color-alabaster;
  background-position: 100% bottom;
  background-repeat: no-repeat;
  width: calc(100% + #{$grid-gutter-width * 2});
  margin-left: -$grid-gutter-width;

  @include media(">=sm") {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 55px 40px 70px 50px;
    width: 100%;
    margin-left: 0;
  }

  @include media(">=md") {
    padding: 55px 80px 70px 60px;
  }
}

.cta__wrapper {
  margin-bottom: 50px;

  @include media(">=sm") {
    max-width: 340px;
    margin-bottom: 0;
  }

  @include media(">=md") {
    max-width: 400px;
  }
}

.cta__title {
  @include primary-large;
  margin-bottom: 15px;
}

.cta__text {
  @include secondary-base;
}
