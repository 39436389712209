/**
 * Creates common z-index helpers
 *
 * For example:
 * 
 * .z-xs-3
 **/
$zIndexes: 1, 2, 3;

@mixin calcZindex($breakpoint) {
  @each $zIndex in $zIndexes {
    .z-#{$breakpoint}-#{$zIndex} {
      z-index: #{$zIndex};
    }
  }
}

@each $breakpoint, $value in $breakpoints {
  @if $breakpoint != "xs" {
    @include media(">=#{$breakpoint}") {
      @include calcZindex($breakpoint);
    }
  } @else {
    @include calcZindex("xs");
  }
}
