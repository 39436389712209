.scroll-panel {
  width: 100%;
  // padding: 40px 0;

  @include media(">=sm") {
    // padding: 60px 0 60px 100px;
  }
}

.scroll-panel__content {
  @include hide-scrollbar;
  overflow: scroll;
}

.scroll-panel__buttons {
  display: inline-block;
  margin-left: auto;
  margin-top: 15px;
}

.scroll-panel__button {
  @include button-reset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 9px;
  margin-left: 5px;
  cursor: pointer;
}

.scroll-panel__button--reverse {
  transform: rotate(180deg);
}

/* Axis */

.scroll-panel--vertical {
  display: flex;

  .scroll-panel__content {
    flex: 1;
    max-height: 840px;
    padding-left: 75px;

    @include media(">=sm") {
      padding-left: 100px;
    }
  }

  .scroll-panel__controller {
    padding-top: 20%;
  }

  .scroll-panel__track {
    position: relative;
    width: 1px;
    height: 100%;
  }

  .scroll-panel__thumb {
    position: relative;
    width: 100%;
    height: 0;
  }
}

.scroll-panel--horizontal {
  .scroll-panel__content {
    white-space: nowrap;
    padding-bottom: 40px;

    @include media(">=sm") {
      padding-bottom: 80px;
    }
  }

  .scroll-panel__controller {
    width: 100%;
    text-align: right;

    @include media(">=sm") {
      width: 80%;
    }
  }

  .scroll-panel__track {
    position: relative;
    width: 100%;
    height: 1px;
  }

  .scroll-panel__thumb {
    position: relative;
    width: 0;
    height: 100%;
  }
}

.scroll-panel--narrow.scroll-panel--horizontal {
  .scroll-panel__content {
    @include media(">=sm") {
      padding-bottom: 60px;
    }
  }
}

/* Themes */

.scroll-panel--light {
  .scroll-panel__track {
    background-color: $color-alto;
  }
  .scroll-panel__thumb {
    background-color: $color-bunker;
  }
  .scroll-panel__button {
    color: $color-bunker;
  }
}

.scroll-panel--dark {
  .scroll-panel__track {
    background: rgba($color-concrete, 0.2);
  }
  .scroll-panel__thumb {
    background-color: $color-concrete;
  }
  .scroll-panel__button {
    color: $color-concrete;
  }
}
