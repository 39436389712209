.footer {
  background-color: $color-bunker;
}

.footer__logo {
  height: 30px;
  max-width: 175px;

  @include media(">=md") {
    max-width: 220px;
    height: 40px;
  }
}

.footer__title {
  @include primary-medium;
  color: $color-concrete;
  margin-bottom: 20px;
}

.footer__link {
  color: $color-concrete;
  border-bottom: 1px solid $color-concrete;
}

.footer__text {
  @include secondary-base;
  color: $color-concrete;
}

.footer__copyright {
  @include secondary-small;
  color: $color-alto;
}
